/* Sidebar Styles */
:root{
    --main-color:#e52579;
    --second-color: #252c58;
    --third-color: #252c58;
    --widget-bg: #e8eafcad ;
}
.sidebar {
    position: fixed;
    height: 100%;
    width: 230px;
    background: var(--second-color);
  
}
.navbar-home img {
    max-height: 115px;
    width: auto;
    position: absolute;
    top: -12px;
}

/* .app-logo {
    width: 200px !important;
    height: auto !important;
    margin-left: -250px;
    margin-top: 10px;
} */

/* Toggle Button */
.toggle-btn {
    width: 20px; /* Adjust size */
    height: 20px;
    background-color:var(--main-color); /* Custom color (Orange-Red) */
    color: white;
    border: 3px solid #d32f2f; /* Visible border */
    border-radius: 25%; /* Makes it circular */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.2s;
}

.toggle-btn:hover {
    background-color:var(--main-color); /* Darker red on hover */
    transform: scale(1.1); /* Slight enlarge effect */
}
.toggle-btn:active {
    transform: scale(0.9); /* Slight shrink effect on click */
}

/* Sidebar Title */
.sidebar-title {
    width: 250px !important;
}

/* Logo Details */
.sidebar .logo-details {
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
}

.sidebar .logo-details i {
    font-size: 30px;
    color: #fff;
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
}

.sidebar .logo-details .logo_name {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    transition: 0.3s ease 0.1s;
}

.sidebar.close .logo-details .logo_name {
    opacity: 0;
    pointer-events: none;
}

/* Navigation Links */
.sidebar .nav-links {
    margin-top:50%;
    height: 100%;
    padding: 0 0 150px 0;
    transition: all 0.5s ease;
    overflow-y: auto;
    overflow-x: clip;
    scrollbar-width: none;
}

.sidebar .nav-links::-webkit-scrollbar {
    display: none;
}

.sidebar .nav-links li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
}

.sidebar .nav-links li .icon-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar.close .nav-links li .icon-link {
    display: block;
}

.sidebar .nav-links li i {
    height: 50px;
    min-width: 60px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sidebar .nav-links li.showMenu i.arrow {
    transform: rotate(-180deg);
}

.sidebar.close .nav-links i.arrow {
    display: none;
}

.sidebar .nav-links li a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.sidebar .nav-links li a .link_name {
    font-size: 18px;
    font-weight: 200;
    color: #fff;
    transition: all 0.4s ease;
    white-space: nowrap;
    overflow: hidden;
}

.sidebar.close .nav-links li a .link_name {
    opacity: 0;
    pointer-events: none;
}

/* Sub-menu Styles */
.sidebar .nav-links li .sub-menu {
    padding: 6px 6px 14px 60px;
    margin-top: -10px;
    display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
    display: block;
}

.sidebar .nav-links li .sub-menu a {
    color: #fff;
    font-size: 15px;
    padding: 5px 0;
    opacity: 0.6;
    transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu a:hover {
    opacity: 1;
}

.sidebar.close .nav-links li .sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    opacity: 0;
    display: block;
    pointer-events: none;
}

.sidebar.close .nav-links li:hover .sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
    display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name {
    font-size: 18px;
    opacity: 1;
    display: block;
}

/* Profile Details */
.sidebar .profile-details {
    position: fixed;
    bottom: 0;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1d1b31;
    padding: 12px 0;
    transition: all 0.5s ease;
}

.sidebar.close .profile-details {
    background: none;
    width: 78px;
}

.sidebar .profile-details .profile-content {
    display: flex;
    align-items: center;
}

.sidebar .profile-details img {
    height: 52px;
    width: 52px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    background: #1d1b31;
    transition: all 0.5s ease;
}

.sidebar.close .profile-details img {
    padding: 10px;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
    display: none;
}

.sidebar .profile-details .job {
    font-size: 12px;
}

/* Home Section */
.home-section {
    position: relative;
    background: #e4e9f7;
    height: 100vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
}

.sidebar.close ~ .home-section {
    left: 78px;
    width: calc(100% - 78px);
}

/* Select language styles */
.dropbtn {
   
    padding: 2px 5px;
    font-size: 13px;
    border: 1px solid #f3f3f3;
    cursor: pointer;
    border-radius: 5px;
}

/* Dropdown container */
.dropdown {
    position: relative;
    display: inline-block;
    margin-top: 10px;
}

/* Dropdown content */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--second-color);
    min-width: 110px;
    max-height: 320px;
    overflow-y: auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding-left: 5px;
    border-radius: 5px;
}

/* Dropdown list items */
.dropdown-content li {
    color: #f3eded;
    padding: 5px 0;
    text-decoration: none;
    display: block;
    text-align: center;
    cursor: pointer;
    font-size: 13px;
}

/* Hover effects for list items */
.dropdown-content li:hover {
    background-color: #e7eafc;
    color: #000;
    margin: 2px 10px;
    border-radius: 5px;
}

/* Show dropdown menu */
.show {
    display: block;
}

/* Loading overlay styles */
#loadingOverlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: progress;
    display: flex;
    align-items: center;
    justify-content: center;
}

#loadingOverlay div {
    display: inline-block;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media only screen and (min-width: 320px) and (max-width: 768px) {
    .toggle-btn {
        display: block; 
    }
    .sidebar {
        display: none;
    }
    .sidebar.open {
        display: block; 
    }
}

.sidebar:hover .page-container.page-body.page-content {
    padding-left: 290px; 
}

/* User Info Styles */
.dv-user-info {
    margin: 0px;
    overflow: hidden;
    color: #fff;
    padding: 2px;
    border-radius: 2%;
}

.dv-user-info p.user-name {
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #faf9f9;
    text-align: center;
}

.dv-user-info span.user-status {
    font-size: 10px;
    color: #e2e2da;
    text-align: center;
}
@media only screen and (min-width: 769px) and (max-width: 940px){
.sidebar .nav-links li a .link_icon {
    display: none;
    margin: 10px;
}
.sidebar {
    position: fixed;
    height: 100%;
    width: 150px;
    background: var(--second-color);
}
.sidebar .nav-links li a .link_name {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    transition: all 0.4s ease;
    white-space: nowrap;
    overflow: hidden;
}
.sidebar .nav-links li a {
    display: flex
;
    align-items: center;
    text-decoration: none;
    position: relative;
    left: 18px;
}
}
@media only screen and  (min-width: 824px) and (max-width: 940px){

    .navbar-home img {
        max-height: 115px;
        width: auto;
        position: absolute;
        top: -12px;
        
    }
    .sidebar {
        position: fixed;
        height: 100%;
        width: 150px;
        background: var(--second-color);
    }
    .sidebar .nav-links li a .link_name {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        transition: all 0.4s ease;
        white-space: nowrap;
        overflow: hidden;
    }
    .sidebar .nav-links li a {
        display: flex
    ;
        align-items: center;
        text-decoration: none;
        position: relative;
        left: 18px;
    }
}
@media only screen and (min-width:966px) and (max-width: 1051px){
    .navbar-home img {
        max-height: 115px;
        width: auto;
        position: absolute;
        top: -2px;
        left: 40px;
    }
}
/* Responsive Design for Larger Screens */
@media (min-width: 1600px) {
    .sidebar {
        top: 0%; /* Adjust sidebar's top position for medium screens */
    }

    .sidebar-logo {
        top: 4%; /* Adjust logo's top position for medium screens */
    }
}

/* Medium Screens */
@media (min-widthmin: 992px) {
    .sidebar {
        width: 200px;
        border-radius: 0 15px 15px 0;
       display: none; 
    }

    .home-section {
        left: 200px;
        width: calc(100% - 200px);
    }

    .sidebar.close {
        width: 60px;
    }

    .sidebar.close ~ .home-section {
        left: 60px;
        width: calc(100% - 60px);
    }
}

/* Small Screens */
@media (max-width: 480px) {
    .sidebar {
        width: 160px;
    }

    .home-section {
        left: 0;
    }

    .sidebar .logo-details {
        height: 50px;
    }

    .sidebar .nav-links li a .link_name {
        font-size: 14px;
    }

    .sidebar .profile-details {
        width: 160px;
    }

    .sidebar.close .profile-details {
        width: 60px;
    }
}
